import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: "#000000",
    main: "#051426",
    light: "#2d3a4e",
  },
  secondary: {
    contrastText: white,
    dark: "#00417e",
    main: "#0d6bae",
    light: "#5799e0",
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    listItem: colors.blueGrey[800],
    listItemActive: "#051426",
    link: colors.blue[600]
  },
  status: {
    contrastText: white,
    active: colors.green[600],
    inactive: colors.red[600],
    testMode: colors.blue[600],
    monitoring: colors.purple[600],
    validation: colors.amber[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white,
    accent: colors.grey[200],
    scrollBar: "#393939",
  },
  divider: colors.grey[200]
};
