import React, {useContext, useEffect, useState} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {light} from './theme';
import Dashboard from "./layouts/Dashboard";
const App = () => {
  return (
      <ThemeProvider theme={light}>
          <Dashboard/>
      </ThemeProvider>
  );
};

export default App;
