import React, {createRef, useContext, useEffect, useRef, useState} from "react";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import validate from "validate.js";

// Material-UI
import {
    makeStyles,
    Grid,
    Typography,
    colors,
} from '@material-ui/core';
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {uploadFile} from "../../../../services/integration";
import CircularProgress from "@material-ui/core/CircularProgress";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        "&:hover": {
            backgroundColor: colors.green[900]
        }
    },
    uploadButton: {
        width: "100%",
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        "&:hover": {
            backgroundColor: colors.blue[900]
        }
    },
    fileInput: {
        display: "none"
    }
}));

const schema = {
    email: {
        presence: { allowEmpty: false, message: "is required" },
        email: true,
        length: {
            maximum: 64
        }
    },
    subject: {
        presence: { allowEmpty: false, message: "is required" },
    },
    firstName: {
        presence: { allowEmpty: false, message: "is required" },
    },
    company: {
        presence: { allowEmpty: false, message: "is required" },
    },
    lastName: {
        presence: { allowEmpty: false, message: "is required" },
    },
    description: {
        presence: { allowEmpty: false, message: "is required" },
    }
};

const DeskForm = ({submitForm, className, setLoading, successToast, failToast}) => {
    const classes = useStyles();

    const [attachLoading, setAttachLoading] = useState(false);

    const [formState, setFormState] = useState({
        isValid: false,
        values: {
            subject: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            priority: "",
            supportType: "",
            contentPart: "",
            contentOther: "",
            workflow: "",
            workflowOther: "",
            workflowPart: "",
            workflowPartOther: "",
            encompassPart: "",
            encompassPartOther: "",
            wouldLike: "",
            wouldLikeOther: "",
            description: "",
            files: []
        },
        attachLoading: false,
        touched: {},
        errors: {}
    });

    const errors = validate(formState.values, schema);

    useEffect(() => {
        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);

    const setNewFileUpload = (fileUrl) => {
        var hold = formState.values.files;
        hold.push(fileUrl);

        setFormState(formState => ({
            ...formState,
            values:{
                ...formState.values,
                files: hold
            }
        }));
    };

    const handleChange = (event) => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === "checkbox"
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const hasError = (field) =>
        !!(formState.touched[field] && formState.errors[field]);

    let fileInputRef = createRef();

    const openInput = () => {
        fileInputRef.current.click();
    };

    const handleAttach = async () => {
        try {
            if (fileInputRef.current.files.length > 0) {
                let file = fileInputRef.current.files[0];
                if(file.size > 1050000){
                    failToast("Files must be smaller than 1MB");
                    return;
                }

                setAttachLoading(true);

                let reader = new FileReader();
                let fileByteArray = [];
                reader.readAsArrayBuffer(file);
                reader.onloadend = async (evt) => {
                    try {
                    if (evt.target.readyState === FileReader.DONE) {
                        let arrayBuffer = evt.target.result,
                            array = new Uint8Array(arrayBuffer);
                        for (let i = 0; i < array.length; i++) {
                            fileByteArray.push(array[i]);
                        }
                        let name = file.name.replace(/\s/g, '');
                        let upload = await uploadFile({file: fileByteArray, fileName: name});
                        let fileUrl = upload.response.data.payload.fileUrl;
                        setNewFileUpload(fileUrl);
                        successToast("File uploaded");
                        setAttachLoading(false);
                    }
                    } catch(err){
                        failToast("An error occurred while uploading this file. Please e-mail it to us directly.");
                        setAttachLoading(false);
                    }

                }
            }
        } catch(err){
            failToast("An error occurred while uploading this file. Please e-mail it to us directly.")
            setAttachLoading(false);
        }
    };

    const submit = event => {
        setLoading(true);
        let hold = formState.values;
        if(formState.values.contentPart.toLowerCase() === "other")
            hold.contentPart = `Other: ${formState.values.contentOther}`;

        if(formState.values.workflow.toLowerCase() === "other")
            hold.workflow = `Other: ${formState.values.workflowOther}`;

        if(formState.values.workflowPart.toLowerCase() === "other")
            hold.workflowPart = `Other: ${formState.values.workflowPartOther}`;

        if(formState.values.wouldLike.toLowerCase() === "other")
            hold.wouldLike = `Other: ${formState.values.wouldLikeOther}`;

        setFormState({
            ...formState,
            values: {
                ...formState.values,
                ...hold
            },
        });

        submitForm(formState.values);
    };

    return (
        <div className={className}>
            <Typography variant={"h1"} style={{textAlign: "center"}}>Submit a support request</Typography>
            <Typography variant={"h3"} style={{textAlign: "center"}}>New requests are monitored: 8-5 (MT)
                M-F</Typography>

            <form onSubmit={submit}>
                <CardContent>
                    <Grid
                        container
                        spacing={4}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                error={hasError("subject")}
                                helperText={
                                    hasError("subject") ? formState.errors.subject[0] : null
                                }
                                label="Request Title"
                                name="subject"
                                onChange={handleChange}
                                required
                                value={formState.values.subject || ""}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                error={hasError("firstName")}
                                helperText={
                                    hasError("firstName") ? formState.errors.firstName[0] : null
                                }
                                label="First name"
                                name="firstName"
                                onChange={handleChange}
                                required
                                value={formState.values.firstName || ""}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Last name"
                                error={hasError("lastName")}
                                helperText={
                                    hasError("lastName") ? formState.errors.lastName[0] : null
                                }
                                name="lastName"
                                onChange={handleChange}
                                required
                                value={formState.values.lastName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                error={hasError("email")}
                                helperText={
                                    hasError("email") ? formState.errors.email[0] : null
                                }
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                type="email"
                                required
                                value={formState.values.email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                error={hasError("phone")}
                                helperText={
                                    hasError("phone") ? formState.errors.phone[0] : null
                                }
                                label="Phone"
                                name="phone"
                                type="tel"
                                onChange={handleChange}
                                value={formState.values.phone}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Company"
                                error={hasError("company")}
                                helperText={
                                    hasError("company") ? formState.errors.company[0] : null
                                }
                                name="company"
                                onChange={handleChange}
                                required
                                value={formState.values.company}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Divider style={{marginBottom: "30px"}}/>

                            <FormControl component="fieldset">
                                <FormLabel component="legend">Does this request have a priority?</FormLabel>
                                <RadioGroup aria-label="priority" name="priority" value={formState.values.priority}
                                            onChange={handleChange}>
                                    <FormControlLabel value="P1" control={<Radio/>} label="Services Down"/>
                                    <FormControlLabel value="P2" control={<Radio/>} label="Severe Impact"/>
                                    <FormControlLabel value="P3" control={<Radio/>} label="Change Request"/>
                                    <FormControlLabel value="P4" control={<Radio/>} label="Enhancement"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Divider style={{marginBottom: "30px"}}/>

                            <FormControl component="fieldset">
                                <FormLabel component="legend">What type of support do you need?</FormLabel>
                                <RadioGroup aria-label="supportType" name="supportType" value={formState.values.supportType}
                                            onChange={handleChange}>
                                    <FormControlLabel value="content" control={<Radio/>} label="Content"/>
                                    <FormControlLabel value="workflow" control={<Radio/>} label="Workflow"/>
                                    <FormControlLabel value="encompass" control={<Radio/>} label="Encompass"/>
                                    <FormControlLabel value="new" control={<Radio/>} label="Something New"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {formState.values.supportType === "content" ? (
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Divider style={{marginBottom: "30px"}}/>

                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Which part of the content?</FormLabel>
                                    <RadioGroup aria-label="contentPart" name="contentPart" value={formState.values.contentPart}
                                                onChange={handleChange}>
                                        <FormControlLabel value="Text-Changes" control={<Radio/>} label="Text Changes"/>
                                        <FormControlLabel value="Images-Design-Layout" control={<Radio/>}
                                                          label="Images/Design/Layout"/>
                                        <FormControlLabel value="Fields-Forms" control={<Radio/>} label="Fields/Forms"/>
                                        <FormControlLabel value="Other" label={
                                            <TextField
                                                fullWidth
                                                disabled={formState.values.contentPart !== "Other"}
                                                label="Other"
                                                name="contentOther"
                                                onChange={handleChange}
                                                value={formState.values.contentOther}
                                                variant="outlined"
                                            />
                                        } control={
                                            <Radio/>
                                        }/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        ) : null}

                        {formState.values.supportType === "workflow" ? (
                            <>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider style={{marginBottom: "30px"}}/>

                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Which workflow can we help with?</FormLabel>
                                        <RadioGroup aria-label="workflow" name="workflow" value={formState.values.workflow}
                                                    onChange={handleChange}>
                                            <FormControlLabel value="Loan-Process-Communication" control={<Radio/>}
                                                              label="Loan Process Communication"/>
                                            <FormControlLabel value="Compliance-ECOA" control={<Radio/>}
                                                              label="Compliance (ECOA/RegB)"/>
                                            <FormControlLabel value="Internal-Communication" control={<Radio/>}
                                                              label="Internal Communication (CoC)"/>
                                            <FormControlLabel value="Appraisal-Payment" control={<Radio/>}
                                                              label="Appraisal Payment"/>
                                            <FormControlLabel value="Other" label={
                                                <TextField
                                                    fullWidth
                                                    disabled={formState.values.workflow !== "Other"}
                                                    label="Other"
                                                    name="workflowOther"
                                                    onChange={handleChange}
                                                    value={formState.values.workflowOther}
                                                    variant="outlined"
                                                />
                                            } control={
                                                    <Radio/>
                                            }/>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider style={{marginBottom: "30px"}}/>

                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">What part of the workflow?</FormLabel>
                                        <RadioGroup aria-label="workflowPart" name="workflowPart" value={formState.values.workflowPart}
                                                    onChange={handleChange}>
                                            <FormControlLabel value="integrations" control={<Radio/>}
                                                              label="Integrations (Folders/Fields/Senders/Recipients)"/>
                                            <FormControlLabel value="events-actions-filters" control={<Radio/>}
                                                              label="Events/Actions/Filters"/>
                                            <FormControlLabel value="company-settings" control={<Radio/>}
                                                              label="Company Settings"/>
                                            <FormControlLabel value="sender-settings" control={<Radio/>}
                                                              label="Sender Settings"/>
                                            <FormControlLabel value="reports" control={<Radio/>}
                                                              label="Reports"/>
                                            <FormControlLabel value="Other" label={
                                                <TextField
                                                    fullWidth
                                                    label="Other"
                                                    name="workflowPartOther"
                                                    disabled={formState.values.workflowPart !== "Other"}
                                                    onChange={handleChange}
                                                    value={formState.values.workflowPartOther}
                                                    variant="outlined"
                                                />
                                            } control={
                                                    <Radio/>
                                            }/>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </>
                        ) : null}

                        {formState.values.supportType === "encompass" ? (
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Divider style={{marginBottom: "30px"}}/>

                                <FormControl component="fieldset">
                                    <FormLabel component="legend">What part of Encompass?</FormLabel>
                                    <RadioGroup aria-label="encompassPart" name="encompassPart" value={formState.values.encompassPart}
                                                onChange={handleChange}>
                                        <FormControlLabel value="access-persona" control={<Radio/>} label="Access/Persona"/>
                                        <FormControlLabel value="custom-form" control={<Radio/>}
                                                          label="Custom Form"/>
                                        <FormControlLabel value="custom-fields" control={<Radio/>} label="Custom Fields"/>
                                        <FormControlLabel value="business-rules" control={<Radio/>} label="Business Rules"/>
                                        <FormControlLabel value="encompass-data" control={<Radio/>} label="Encompass Data"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        ) : null}

                        {formState.values.supportType === "new" ? (
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Divider style={{marginBottom: "30px"}}/>

                                <FormControl component="fieldset">
                                    <FormLabel component="legend">What would you like?</FormLabel>
                                    <RadioGroup aria-label="wouldLike" name="wouldLike" value={formState.values.wouldLike}
                                                onChange={handleChange}>
                                        <FormControlLabel value="new-workflow" control={<Radio/>} label="New Workflow"/>
                                        <FormControlLabel value="new-feature" control={<Radio/>} label="New Feature"/>
                                        <FormControlLabel value="Other" label={
                                            <TextField
                                                fullWidth
                                                disabled={formState.values.wouldLike !== "Other"}
                                                label="Other"
                                                name="wouldLikeOther"
                                                onChange={handleChange}
                                                value={formState.values.wouldLikeOther}
                                                variant="outlined"
                                            />
                                        } control={
                                            <Radio/>
                                        }/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        ) : null}

                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Badge badgeContent={formState.values.files.length} color="primary">
                            <Button
                                className={classes.uploadButton}
                                variant="text"
                                disabled={formState.attachLoading}
                                onClick={openInput}
                            >
                                <>
                                {!attachLoading ?(
                                    <span>Upload file (1MB)</span>
                                ) : (
                                    <CircularProgress color="secondary"/>
                                )}
                                </>
                            </Button>
                            </Badge>
                            <input
                                onChange={handleAttach}
                                className={classes.fileInput}
                                ref={fileInputRef}
                                id="hiddenInput"
                                type="file"
                            />
                        </Grid>

                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                multiline
                                fullWidth
                                label="Description"
                                name="description"
                                error={hasError("description")}
                                helperText={
                                    hasError("description") ? formState.errors.description[0] : null
                                }
                                onChange={handleChange}
                                required
                                value={formState.values.description}
                                variant="outlined"
                                rows={"6"}
                            />
                        </Grid>

                    </Grid>
                </CardContent>
                <Divider/>
                <CardActions>
                    <Button
                        className={classes.saveButton}
                        type="submit"
                        disabled={!formState.isValid}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </CardActions>
            </form>
        </div>
    )
};

export default DeskForm;
