import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const Footer = ({className}) => {
    return (
        <div className={className} style={{width: "100%"}}>
            <Grid container
                  justify={"space-between"}
                  alignItems={"flex-end"}>
                <Typography variant={"body2"} style={{color: "#ffffff", marginLeft: "10px"}}>
                    support.connector.velma.com
                </Typography>
                <Typography variant={"body2"} style={{color: "#ffffff", marginRight: "10px"}}>
                    {new Date().getFullYear()} NSN Solutions, Inc | All rights reserved.
                </Typography>
            </Grid>
        </div>
    );
};

export default Footer;
