import React, {useContext, useEffect, useState} from "react";
import {
    colors,
    makeStyles,
    Divider,
    Tab,
    Tabs,
} from "@material-ui/core";
import {ProgressSpinner} from "../../components";
import {createTicket} from "../../services/integration";
import DeskForm from "./components/DeskForm";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "100%",
        margin: "0 auto",
        padding: `${theme.spacing(3)}px ${theme.spacing(3) * 2}px`,
    },
    tabs: {
        marginTop: theme.spacing(3),
        color: theme.palette.text.primary,
    },
    divider: {
        backgroundColor: theme.palette.divider,
    },
    form: {
        width:"50%",
        color: theme.palette.primary.light,
        margin: "60px auto"
    },
    content: {
        marginTop: theme.spacing(3)
    },
    progress: {
        height: "60vh",
    },
}));

const Ticket = ({className}) => {
    const [loading, setLoading] = useState(false);
    const [openSuccToast, setOpenSuccToast] = useState({
        open: false,
        message: ""
    });
    const [openFailToast, setOpenFailToast] = useState({
        open: false,
        message: ""
    });

    const failToast = (message) => {
        setOpenFailToast({
            message: message,
            open: true
        });
    };

    const successToast = (message) => {
        setOpenSuccToast({
            message: message,
            open: true
        });
    };

    const classes = useStyles();

    const submitForm = async (formValues) => {
        let fail = false;
        setLoading(true);
        try {
            setTimeout(async ()=>{
                fail = true;
            }, 35000);

            var submitRes = await createTicket(formValues);
            if(submitRes.message && !fail) {
                setLoading(false);
                successToast("Request submitted. We will reach out to you shortly.")
            } else
                throw new Error("Error ocurred");

        } catch(error){
            setLoading(false);
            failToast("An error occurred while submitting your request. Please try again or reach out to help@velma.com")
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccToast({
            message: "",
            open: false
        });

        setOpenFailToast({
            message: "",
            open: false
        });
    };

    return (
        <>
            {
                !loading ? (
                        <>
                            <Divider className={classes.divider}/>
                            <div className={classes.content}>
                                    <DeskForm submitForm={submitForm} successToast={successToast} failToast={failToast} setLoading={setLoading} className={classes.form}/>
                            </div>
                        </>
                    ) :
                    <ProgressSpinner
                        height={"60vh"}
                        message={"Submitting support ticket"}
                        size={75}
                    />
            }
            <>
            <Snackbar open={openSuccToast.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal:'right'}}>
                <Alert onClose={handleClose} severity="success">
                    {openSuccToast.message}
                </Alert>
            </Snackbar>
            <Snackbar open={openFailToast.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal:'right'}}>
                <Alert onClose={handleClose} severity="error">
                    {openFailToast.message}
                </Alert>
            </Snackbar>
            </>
        </>
    )
};

export default Ticket;
