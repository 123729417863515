import React, { Suspense, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Footer from "./components/Footer";
import TopBar from "./components/TopBar";
import Ticket from "../../views/Ticket";


const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    },
    topBar: {
        zIndex: 2
    },
    ticket: {
        backgroundColor: "blue"
    },
    footer: {
        zIndex: 4,
        position: "fixed",
        bottom: 0,
        padding: 5,
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        left: 0
    }
}));

const Dashboard = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TopBar
                className={classes.topBar}
            />
            <Ticket className={classes.ticket}/>
            <Footer
                className={classes.footer}/>
        </div>
    );
};

Dashboard.propTypes = {
    route: PropTypes.object
};

export default Dashboard;
