import React from "react";

// Material-UI
import {
    CircularProgress,
    Grid,
    Typography,
} from "@material-ui/core"

const ProgressSpinner = ({message, size, height}) => {
    return (
        <Grid
            style={{height: height}}
            container
            direction={"column"}
            justify={"center"}
            alignItems={"center"}>
            <Typography variant={"h3"}
                        style={{marginBottom: 25, textAlign: "center"}}>
                {message}
            </Typography>
            <CircularProgress
                size={size}
                color={"secondary"}
            />
        </Grid>
    )
};

export default ProgressSpinner;
