import {post} from "../api";

export const createTicket = async (payload) => {
    try {
        let request = {
            integrationSelector:"RequestOriginatedTeamworkProvider:SubmitTeamworkRequest",
            payload: {
                ...payload
            }
        };

        let headers = {
            "Content-Type": "application/json",
            "x-api-version": 1.0
        };

        const response = await post("https://microservice.velma.com/integrations/invoke", request, {headers});
        return {
            message: "Successfully submitted ticket!",
            response,
        }
    } catch(error){
        return {
            error: error?.response?.data?.message,
            obj: error,
        }
    }
};

export const uploadFile = async (payload) => {
    try {
        let request = {
            integrationSelector:"RequestOriginatedAwsProvider:UploadFile",
            payload: {
                requester:"https://support.connector.velma.com",
                ...payload
            }
        };

        let headers = {
            "Content-Type": "application/json",
            "x-api-version": 1.0
        };
        const response = await post("https://microservice.velma.com/integrations/invoke", request, {headers});
        return {
            message: "Successfully uploaded file!",
            response,
        }
    } catch(error){
        return {
            error: error?.response?.data?.message,
            obj: error,
        }
    }
};
